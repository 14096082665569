.todos {
	margin: 50px 0;
	position: relative;
	background-color: #fff;
	width: 100%;
	max-width: 700px;
	padding: 10px 20px;

	@include XGA {
		padding: 10px 30px;
	}

	.todo-input {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: #dedede;
		}

		input {
			border: none;
			width: calc( 100% - 40px );
			padding: 14px 0;
			margin-left: 10px;
			outline: none;
		}

		.add {
			position: absolute;
			top: 4px;
			right: 10px;
			font-size: 30px;
			color: #dedede;
			border: none;
			background: none;
			padding: 0;
			margin: 0;
			cursor: pointer;
		}
	}

	.todo-list {
		margin: 40px 0;

		li {
			position: relative;
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin: 20px 0;
			padding-left: 40px;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				border: #dedede solid 1px;
				cursor: pointer;
			}

			&:after {
				content: "";
				cursor: pointer;
			}

			&.checked {
				&:after {
					content: "";
					position: absolute;
					left: 6px;
					top: 2px;
					width: 16px;
					height: 8px;
					border-left: $yellow solid 5px;
					border-bottom: $yellow solid 5px;
					transform: rotate(-45deg);
				}
			}

			.delete-todo {
				position: absolute;
				top: 0;
				right: 0;
				min-width: 20px;
				max-width: 20px;
				width: 20px;
				top: -2px;
				cursor: pointer;
			}
		}
	}
}