header {
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	opacity: 0;
	transform: translateY(-40px);
	transition: transform .3s $QuadEaseInOut, opacity .3s $QuadEaseInOut, width .3s $QuadEaseInOut;
	z-index: 1000;

	.selected-domain {
		display: none;

		@include XGA {
			display: block;
			position: absolute;
			left: 70px;
			top: 15px;
			color: $yellow;
			text-transform: uppercase;
			cursor: pointer;
		}
	}

	@include XGA {
		width: calc( 100% - 80px );
	}

	@include UXGA {
		width: calc( 100% - 110px );
	}

	&.anim {
		opacity: 1;
		transform: translateY(0);
	}

	&.sticky {
		.header-inner {
			margin: 0;

			&:before {
				width: 100%;
				left: 0;
			}
		}
	}

	&.nav-open {
		@include XGA {
			width: calc( 100% - 260px );
		}

		@include HD {
			width: calc( 100% - 300px );
		}

		@include UXGA {
			width: calc( 100% - 360px );
		}
	}

	&.nav-closed.heatmap-open {
		opacity: 0.05;

		&:hover {
			opacity: 1;
		}
	}

	.header-inner {
		position: relative;
		margin: 20px;
		margin-bottom: 0;
		background-color: #fff;
		padding: 10px 20px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 30px;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		transition: margin .3s $QuadEaseInOut;

		@include XGA {
			margin: 32px;
		}

		.user {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			justify-content: flex-end;

			a {
				color: #000;
				text-decoration: none;
			}

			.btn-logout {
				margin: 0;
				padding: 5px 10px;
				font-size: 13px;
				margin: 7px 0;

				@include XGA {
					padding: 8px 20px;
					margin: 14px 0;
				}
			}

			.name {
				display: none;

				@include XGA {
					display: block;
					margin-right: 10px;
					margin-top: 20px;
				}

				@include HD {
					margin-top: 23px;
				}

				@include UXGA {
					margin-right: 20px;
				}
			}

			.avatar {
				width: 30px;
				height: 30px;
				background-size: contain;
				background-repeat: no-repeat;
				margin-top: 5px;
				margin-right: 20px;

				@include XGA {
					width: 40px;
					height: 40px;
					margin-top: 12px;
				}

				@include HD {
					margin-right: 30px;
				}

				@include UXGA {
					margin-right: 45px;
				}
			}
		}

		&:before {
			content: "";
			position: absolute;
			display: block;
			left: -10px;
			top: -10px;
			width: calc( 100% + 20px );
			height: 50px;
			z-index: -1;
			background-color: $yellow;
			transition: all .3s $QuadEaseInOut;

			@include XGA {
				left: -16px;
				top: -16px;
				width: calc( 100% + 32px );
			}
		}
	}

	.burger {
		position: absolute;
		left: 20px;
		top: 0;
		display: inline-block;
		width: 25px;
		height: 20px;
		margin-top: 20px;
		z-index: 2000;
		cursor: pointer;

		@include XGA {
			margin-top: 30px;
		}

		.line {
			width: 25px;
			height: 3px;
			background-color: $yellow;
			transition: background-color .3s $CubicEaseInOut .3s, width .3s $CubicEaseInOut;

			&:before {
				content: "";
				position: absolute;
				top: 8px;
				left: 0;
				width: 18px;
				height: 3px;
				background-color: $yellow;
				transition: background-color .3s $CubicEaseInOut .3s, width .3s $CubicEaseInOut;
			}

			&:after {
				content: "";
				position: absolute;
				top: 16px;
				left: 0;
				width: 21px;
				height: 3px;
				background-color: $yellow;
				transition: background-color .3s $CubicEaseInOut .3s, width .3s $CubicEaseInOut;
			}
		}

		&.open {
			position: fixed;
			right: 5%;

			.line {
				margin-top: 8px;
				background-color: #000;
				transform: rotate(-45deg);
				transition: background-color .3s $CubicEaseInOut .3s, width .3s $CubicEaseInOut, transform .3s $CubicEaseInOut;

				&:before {
					right: 0;
					top: 0;
					background-color: #000;
					width: 25px;
					transform: rotate(90deg);
					transition: background-color .3s $CubicEaseInOut .3s, width .3s $CubicEaseInOut, transform .3s $CubicEaseInOut;
				}

				&:after {
					opacity: 0;
					background-color: #000;
				}
			}
		}
	}
}