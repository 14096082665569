.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModalPortal > * {
    opacity: 0;
}

.overlay {
    z-index: 9999;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 40px;
	transition: opacity 1000ms $CubicEaseInOut;
    background: transparent;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #43494f;
        opacity: 0.85;
    }

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    @include XGA {
        width: 40px;
        height: 40px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 8px;
        top: 14px;
        width: 14px;
        height: 3px;
        background-color: $yellow;
        transform: rotate(45deg);

        @include XGA {
            top: 19px;
            left: 13px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 8px;
        top: 14px;
        width: 14px;
        height: 3px;
        background-color: $yellow;
        transform: rotate(-45deg);

        @include XGA {
            top: 19px;
            left: 13px;
        }
    }

    span {
        color: #fff;
        text-transform: uppercase;
        font-weight: 400;
        position: absolute;
        top: 6px;
        left: -54px;
        font-size: 14px;

        @include XGA {
            top: 12px;
        }
    }
}

.modal {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -40%);
	transition: transform 300ms $CubicEaseInOut .2s, opacity 300ms $CubicEaseInOut;
    background: transparent;
    max-width: 1000px;
    padding: 20px;
    outline: none;
    border: none;

    .popup-inner {
        max-height: 100vh;
        overflow-y: auto;
    }

    .confirm {
        background-color: #fff;
        padding: 20px 40px;
        text-align: center;

        .btn {
            margin: 0 10px;
            margin-top: 20px;
        }
    }

    &_after-open {
    	opacity: 1;
        transform: translate(-50%, -50%);
    }
    &_before-close {
    	opacity: 0;
        transform: translate(-50%, -40%);
        transition-delay: 0s;
    }

    .sms-verification {
        input {
            padding: 10px;
            border: #027 solid 1px;
            outline: none;
        }
    }

    .select-page-popup {
        width: 90vw;
        max-width: 320px;

        ul {
            margin: 0;

            li {
                padding: 5px;
                text-transform: uppercase;
                font-weight: 600;
                cursor: pointer;
                margin-bottom: 5px;
                transition: background .2s $CubicEaseInOut, color .2s $CubicEaseInOut;

                &:hover {
                    background: $yellow;
                    color: #fff;
                }
            }
        }

        h4 {
            margin-top: 0;
        }
    }

    .fields {
        position: relative;
        margin: 80px 0;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 30px;

        @include HD {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &>div {
            @include HD {
                min-width: 48%;
                width: 48%;
                max-width: 48%;
            }

            &.title {
                @include HD {
                    min-width: 100%;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .input-desc {
            font-size: 10px;
            margin-top: 5px;
        }

        input, select, textarea {
            padding: 10px 20px;
            width: 100%;
            outline: none;
            border: #dcdcdc solid 1px;
        }

        .checkbox {

            input[type="checkbox"] {
                position: absolute;
                left: -30px;
                display: none;
            }

          label {
            position: relative;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin: 0;
            height: 20px;
            margin-bottom: 20px;
            padding-left: 40px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                border: #dedede solid 1px;
                cursor: pointer;
            }

            &:after {
                content: "";
                cursor: pointer;
            }
          }

          input[type="checkbox"]:checked + label {

            &:after {
                content: "";
                position: absolute;
                left: 6px;
                top: 2px;
                width: 16px;
                height: 8px;
                border-left: $yellow solid 5px;
                border-bottom: $yellow solid 5px;
                transform: rotate(-45deg);
            }
          }
        }

        h4 {
            margin-bottom: 10px;

            span {
                color: #f00;
            }
        }

        .add-top, .add-bottom {
            position: absolute;
            right: 0;
            top: -38px;
            background-color: $yellow;
            color: #fff;
            padding: 10px;
            cursor: pointer;
            font-weight: 700;

            @include HD {
                min-width: 0;
                width: auto;
                max-width: 300px;
            }

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }

        .add-bottom {
            top: auto;
            bottom: -38px;
        }

        .back-top, .back-bottom {
            position: absolute;
            left: 0;
            top: -38px;
            background-color: $yellow;
            color: #fff;
            padding: 10px;
            cursor: pointer;
            font-weight: 700;

            @include HD {
                min-width: 0;
                width: auto;
                max-width: 300px;
            }

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }

        .back-bottom {
            top: auto;
            bottom: -38px;
        }
    }
}