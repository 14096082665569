.new {
	.modules {
		&>ul {
			padding: 0;
			
			&>li {
				list-style: none;
				margin: 0;
				padding: 20px;
				border-radius: 6px;
				border: #ddd solid 2px;
				margin-bottom: 5px;
				cursor: initial !important;

				.ck-content {
					cursor: text;
				}
			}
		}
	}

	.error-single {
		border-radius: 5px;
		border: #f44 solid 3px;
		border-color: #f44 !important;
		animation: opacity 1s $CubicEaseInOut infinite;
	}

	.input-error {
		position: relative;
		z-index: 999;

		&::before {
			content: "";
			position: absolute;
			left: -6px;
			top: -6px;
			width: calc( 100% + 6px );
			height: calc( 100% + 6px );
			border-radius: 5px;
			border: #f44 solid 3px;
			animation: opacity 1s $CubicEaseInOut infinite;
			z-index: 9999;
		}
	}

	.slug-input {
		h4 {
			@include HD {
				margin-top: 42px;
			}
		}
	}

	.date {
		font-size: 12px;
		line-height: 1.3;

		@include XGA {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.flex {
		display: flex;

		&>div {
			width: 100%;
			margin: 0 6px;
		}
	}

	.fields {
		position: relative;
		margin: 80px 0;
		padding: 20px 30px;
		background-color: #fff;
		box-shadow: rgba(0, 0, 0, 0.15) 0 0 30px;

		@include HD {
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&>div {
			@include HD {
				min-width: 48%;
				width: 48%;
				max-width: 48%;
			}

			&.title {
				@include HD {
					min-width: 100%;
					width: 100%;
					max-width: 100%;
				}
			}
		}

		.input-desc {
			font-size: 10px;
			margin-top: 5px;
		}

		.flex-input {
			display: flex;

			.btn {
				margin: 0;
				text-align: center;
				padding: 10px 0;
				width: 100px;
				color: #fff;
				font-weight: 600;

				&:hover {
					color: #000;
				}
			}
		}

		.flex-columns {
			@include XGA {
				display: flex;
				flex-flow: row;
				flex-wrap: nowrap;
				justify-content: space-between;

				.ck-editor {
					min-width: calc( 50% - 8px );
					max-width: calc( 50% - 8px );
					width: calc( 50% - 8px );
				}
			}

			&.three {
				@include XGA {
					display: flex;
					flex-flow: row;
					flex-wrap: nowrap;
					justify-content: space-between;
	
					.ck-editor {
						min-width: calc( 33.33% - 8px );
						max-width: calc( 33.33% - 8px );
						width: calc( 33.33% - 8px );
					}
				}
			}
		}

		input, select, textarea {
			padding: 10px 20px;
			width: 100%;
			outline: none;
			border: $yellow solid 1px;
		}

		input[type="color"] {
			height: 40px;
			padding: 0;
		}

		.withbtn {
			display: flex;
		}

		.checkbox {
			min-width: 100%;

			input[type="checkbox"] {
			    position: absolute;
			    left: -30px;
			    display: none;
			}

		  label {
		    position: relative;
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin: 0;
			height: 20px;
			margin-bottom: 20px;
			padding-left: 40px;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				border: #dedede solid 1px;
				cursor: pointer;
			}

			&:after {
				content: "";
				cursor: pointer;
			}
		  }

		  input[type="checkbox"]:checked + label {

		    &:after {
				content: "";
				position: absolute;
				left: 6px;
				top: 2px;
				width: 16px;
				height: 8px;
				border-left: $yellow solid 5px;
				border-bottom: $yellow solid 5px;
				transform: rotate(-45deg);
			}
		  }
		}

		h4 {
			margin-bottom: 10px;

			span {
				color: #f00;
			}
		}

		.add-top, .add-bottom {
			position: absolute;
			right: 0;
			top: -38px;
			background-color: $yellow;
			color: #fff;
			padding: 10px;
			cursor: pointer;
			font-weight: 700;

			@include HD {
				min-width: 0;
				width: auto;
				max-width: 300px;
			}

			&:hover {
				background-color: #fff;
				color: #000;
			}
		}

		.add-bottom {
			top: auto;
			bottom: -38px;
		}

		.back-top, .back-bottom {
			position: absolute;
			left: 0;
			top: -38px;
			background-color: $yellow;
			color: #fff;
			padding: 10px;
			cursor: pointer;
			font-weight: 700;

			@include HD {
				min-width: 0;
				width: auto;
				max-width: 300px;
			}

			&:hover {
				background-color: #fff;
				color: #000;
			}
		}

		.back-bottom {
			top: auto;
			bottom: -38px;
		}
	}

	.tag-management {
		text-align: right;
		margin: 10px 0;

		a {
			color: #000;
			text-decoration: none;
		}
	}

	.barem-list {
		overflow-x: auto;

		.btn-holder {
			text-align: right;
		}

		.btn {
			margin: 20px 0;
		}

		.edit, .delete {
			display: inline-block;
			cursor: pointer;
			margin: 5px 0;
		}

		.delete {
			color: #dd0000;
		}

		table {
			border: #dcdcdc solid 1px;
			padding: 0;
			margin: 20px 0;
			border-spacing: 0;

			tr {
				&:nth-child(even) {
					background-color: #eeeff4;
				}

				&:hover {
					background-color: #f8f8f8;
				}
			}

			th {
				text-align: left;
				padding: 10px 5px;
				border-right: #dcdcdc solid 1px;
				border-bottom: #dcdcdc solid 1px;

				&:last-child {
					border-right: none;
				}
			}

			td {
				padding: 5px;
				border-right: #dcdcdc solid 1px;
				margin: 0;

				&:last-child {
					border-right: none;
				}
			}
		}
	}
}