.side-nav {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: $yellow;
	z-index: 3000;
	width: 100%;
	opacity: 0;
	transform: translateX(-100%);
	transition: transform .3s $QuadEaseInOut, width .3s $QuadEaseInOut;

	.side-logo {
		display: inline-block;
		width: 60px;
		transition: width .2s $CubicEaseInOut;
		height: auto;
	}

	.selected-page {
		position: relative;
		color: #fff;
		padding: 10px 32px;
		margin-bottom: 30px;
		text-transform: uppercase;
		opacity: 0;
		visibility: hidden;
		transition: all .2s $CubicEaseInOut;
		background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, $yellow 100%);

		p {
			position: relative;
			margin: 0;
		}

		span {
			position: absolute;
			right: 0;
			top: 0;
			cursor: pointer;
			text-decoration: underline;
			text-transform: initial;
		}
	}

	.tdy-logo {
		position: absolute;
		left: 0;
		bottom: 20px;
		text-align: center;

		img {
			display: inline-block;
			max-width: 80%;
			margin-bottom: 30px;
			transform: rotate(-90deg);
			transition: all .2s $CubicEaseInOut;
		}
	}

	&.open {
		transform: translateX(0);

		.tdy-logo {
			img {
				transform: rotate(0deg);
				max-width: 30%;
			}
		}

		.selected-page {
			opacity: 1;
			visibility: visible;
		}

		.side-logo {
			width: 120px;
		}

		@include XGA {
			width: 260px;

			.top {
				display: block;
				width: 220px;
				text-align: center;

				span {
					line-height: 43px;
					font-size: 36px;
				}

				.side-logo {
					width: 140px;
				}
			}

			.copyrights {

				.res {
					display: inline-block;
				}
			}
		}

		@include HD {
			.nav-items {
				margin-top: 0px;
			}
		}

		@include UXGA {
			.nav-items {
				margin-top: 90px;
			}
		}

		@include HD {
			width: 300px;
		}

		@include UXGA {
			width: 360px;
		}
	}

	&.anim {
		opacity: 1;
		
		@include XGA {
			transform: translateX(0);
		}
	}

	.close {
		@include XGA {
			display: none;
		}
	}

	@include XGA {
		width: 80px;
	}

	@include UXGA {
		width: 110px;
	}

	.top {
		width: 220px;
		margin: 40px auto;

		@include XGA {
			display: block;
			width: 56px;
		}

		.sun {
			@include XGA {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				width: 29px;
				height: 29px;
				border-radius: 50%;
				margin-bottom: 5px;
				background-color: $yellow;
			}
		}

		.logo {
			@include XGA {
				display: none;
			}
		}

		span {
			letter-spacing: 10px;
			line-height: 45px;
			margin-right: 10px;
			color: #fff;
			transition: all .3s $CubicEaseInOut;

			@include XGA {
				line-height: 32px;
			}
		}
	}

	.copyrights {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		opacity: 0.25;
		font-size: 12px;
		white-space: nowrap;

		@include XGA {
			
			.res {
				display: none;
			}
		}

		@include UXGA {
			bottom: 40px;
		}
	}

	.nav-items {
		padding: 0;
		max-height: calc( 100vh - 300px );
		overflow-y: auto;
		overflow-x: hidden;

		@include UXGA {
			margin-top: 60px;
		}

		li {
			user-select: none;
			list-style: none;

			.expand {
				position: relative;

				&:after {
					content: "";
					position: absolute;
					right: 30px;
					top: 9px;
					width: 10px;
					height: 10px;
					border-left: #fff solid 1px;
					border-bottom: #fff solid 1px;
					transform: rotate(-45deg);
				}

				&.open {
					&:after {
						top: 15px;
						transform: rotate(135deg);
					}
				}
			}

			a, span {
				position: relative;
				display: flex;
				width: 100%;
				padding: 10px 0;
				color: #fff;
				text-transform: uppercase;
				text-decoration: none;
				padding-left: 30px;
				white-space: nowrap;
				transition: color .3s $CubicEaseInOut, background .3s $CubicEaseInOut;
				cursor: pointer;
				font-weight: 600;

				&:before {
					content: "";
					transition: background-color .3s $CubicEaseInOut;
				}

				@include XGA {
					font-size: 13px;
					padding-left: 25px;
				}

				@include UXGA {
					padding: 7px 0;
					padding-left: 40px;
				}

				&.active, &:hover {
					color: #fff;
					background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, $yellow 100%);

					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 3px;
						background-color: #fff;
					}
				}

				.img {
					text-align: center;
					height: 15px;
					width: 30px;
					min-width: 30px;
					margin-right: 20px;
					filter: brightness(200%);

					@include XGA {
						margin-right: 30px;
					}

					@include UXGA {
						margin-right: 40px;
					}

					img {
						display: inline-block;
						height: 16px;
					}
				}
			}
		}
	}
}